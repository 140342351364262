<!--
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2022-06-06 11:22:03
 * @LastEditors: peter
 * @LastEditTime: 2022-06-21 11:18:36
-->
<template>
  <div style="border: 1px solid #ccc; margin-top: 10px">
    <!-- 工具栏 -->
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :default-config="toolbarConfig"
    />
    <!-- 编辑器 -->
    <Editor
      v-model="html"
      style="height: 400px; overflow-y: hidden"
      :default-config="editorConfig"
      mode="default"
      @onChange="onChange"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { SlateTransforms } from '@wangeditor/editor'
import { uploadFile } from '@/utils/api'
import { host } from '@/config'
export default {
  components: {
    Editor,
    Toolbar
  },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              const formData = new FormData()
              formData.append('file', file)
              const res = await uploadFile(formData)
              console.log('res', res)
              const path = `${host}/file/browse/${res.data}`
              insertFn(path)
            }
          }
        }
      },
      html: '',
      editor: null
    }
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    this.html = ''
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  },
  methods: {
    onChange(editor) {
      if (editor) {
        this.$emit('update:content', editor.getHtml())
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor)
    },
    insertNodes(list) {
      SlateTransforms.insertNodes(this.editor, list)
    },
    setHtml(html) {
      this.editor.setHtml(html)
    }
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
</style>
