<template>
  <div>
    <el-dialog
      :visible.sync="detailVisible"
      title="详情"
      width="1000px"
      append-to-body
      @close="handleClose"
    >
      <div class="detail-wrapper">
        <div class="veteran-info-box">
          <img :src="detailData.avatar" />
          <div class="veteran-info">
            <div class="veteran-name">{{ detailData.name }}</div>
            <div>
              <span>性别：{{ detailData.sex }}</span>
              <span>出生日期：{{ detailData.bornDate | F_formatDate }}</span>
              <span>民族：{{ detailData.nation }}</span>
              <span>职级：{{ detailData.position }}</span>
              <span>籍贯：{{ detailData.hometown }}</span>
            </div>
            <div>部队番号：{{ detailData.number }}</div>
            <div>
              <span>入伍时间：{{ detailData.enlistDate | F_formatDate }}</span>
              <span>入伍地点：{{ detailData.enlistPlace }}</span>
            </div>
            <div v-if="detailData.isWhampoa == 1 || detailData.isWhampoa == 0">
              是否黄埔：{{ detailData.isWhampoa == 1 ? '是' : '否' }}
            </div>
            <div v-else>是否黄埔：未知</div>
            <div>老兵状态：{{ detailData.veteranStatus }}</div>
          </div>
        </div>
        <div class="veteran-mid-info">
          <div>发现时间：{{ detailData.findDate | F_formatDate }}</div>
          <div>去世时间：{{ detailData.deathDate | F_formatDate }}</div>
          <!-- <div>备考：{{ detailData.waitFind }}</div> -->
          <div>资料来源：{{ detailData.source }}</div>
          <div>
            <span v-if="detailData.sacrificeDate"
              >阵亡时间：{{ detailData.sacrificeDate | F_formatDate }}</span
            >
            <span v-else>阵亡时间：暂无信息</span>
            <span style="margin-left: 30px"
              >阵亡地点：{{ detailData.sacrificePlace || '暂无信息' }}</span
            >
          </div>
        </div>
        <el-divider />
        <div class="img-wrapper">
          <div>老兵图片</div>
          <div v-if="!detailData.picture" class="img-box">暂无信息</div>
          <div v-else class="img-box">
            <img v-for="item in detailData.picture" :key="item" :src="item" />
          </div>
        </div>
        <el-divider />
        <div class="veteran-des">
          <div class="des-title">老兵介绍</div>
          <div v-if="!detailData.introduction">暂无信息</div>
          <div v-else v-html="detailData.introduction" />
          <!-- <el-image style="margin-top: 5px;" /> -->
        </div>
        <el-divider />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { veteranDetail } from '@/utils/api.js'
export default {
  data() {
    return {
      detailVisible: false,
      id: '',
      detailData: {}
    }
  },
  methods: {
    handleClose() {
      Object.assign(this.$data, this.$options.data.call(this))
    },
    open(id) {
      this.detailVisible = true
      this.getDetail(id)
    },
    async getDetail(id) {
      const form = {}
      const res = await veteranDetail(form, id)
      if (res.code === 200) {
        this.detailData = res.data
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.detail-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px;
  .veteran-info-box {
    height: 150px;
    display: flex;
    color: #333333;
    img {
      width: 140px;
      height: 140px;
    }
    .veteran-info {
      margin-left: 17px;
      .veteran-name {
        font-size: 16px;
      }
      div {
        font-size: 12px;
        margin-bottom: 7px;
        span {
          margin-right: 20px;
        }
      }
    }
  }
  .veteran-mid-info {
    height: 120px;
    font-size: 12px;
    color: #333333;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .img-wrapper {
    box-sizing: border-box;
    font-size: 16px;
    .img-box {
      margin-top: 9px;
      img {
        width: 100px;
        height: 100px;
        margin-left: 5px;
      }
    }
  }
  .des-title {
    margin: 20px 0;
  }
}
.dialog-footer {
  text-align: center;
}
.veteran-des {
  img {
    max-width: 100%;
  }
  p {
    font-size: 40px;
  }
}
</style>
<style>
.veteran-des img {
  width: 100%;

  object-fit: fill;
}
</style>
